* {
    box-sizing: border-box;
    transition: .3s;
}

a {
    text-decoration: none;
}

button {
    background-color: transparent;
    border: none;
}