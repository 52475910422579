.background {
  width: 100vw;
  height: 100vh;
  margin: 0;
  background-image: url(../../images/mobile/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.background::after {
  content: "";
  position: absolute;
  width: 100vw;
  height: 118vh;
  top: 0;
  background-size: contain;
  background-image: url(../../images/mobile/snieg_mobile.png);
  animation: bubbles 3s linear infinite;
}

@media (max-width: 300px) and (orientation: portrait) {
  .background::after {
    height: 100vh;
  }
  }

@media (min-width: 750px) and (orientation: portrait) {
.background::after {
  height: 130vh;
}
}

.background::before {
  content: "";
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../images/desktop/logo_desktop.png);
  z-index: 6;
  width: 40vw;
  height: 40vw;
  top: 0vh;
  left: 30vw;
}

.background__leaves {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3;
}

.background__leaves--top-left {
  background-image: url(../../images//desktop/desk_jemiola.png);
  width: 22vh;
  height: 21vh;
  top: 0vh;
  left: -10vw;
}

.background__leaves--top-right {
  background-image: url(../../images//desktop/desk_listki_duze.png);
  width: 21vh;
  height: 17vh;
  top: -2.5vh;
  right: 0vw;
  background-size: cover;
}

.background__leaves--top-right::after {
  content: "";
  position: absolute;
  background-image: url(../../images/mobile/Logo_Wilga_swieta.png);
  top: 23vw;
  right: 5vw;
  width: 13vw;
  height: 19vw;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 8;
}


@media (min-width: 800px) and (orientation: landscape) {
  .background {
    background-image: url(../../images/desktop/background.png);
    overflow: hidden;
  }

  .background::after {
    background-image: url(../../images/desktop/desk_snieg.png);
  }

  .background::before {
    width: 28vh;
    height: 52vh;
    top: 0vh;
    left: 2vw;
  }

  .background__leaves--top-left {
    width: 35vh;
    height: 35vh;
    top: -5vh;
    left: 30vh;
  }

  .background__leaves--top-right {
    width: 60vh;
    height: 40vh;
    top: -5vh;
    right: 15vh;
  }

  .background__leaves--top-right::after {
    top: 5vw;
    right: -4vw;
    width: 10vh;
    height: 14vh;
    z-index: 15;
  }

  .background__leaves--top-right-lower {
    background-image: url(../../images//desktop/desk_listki1.png);
    width: 20vh;
    height: 23vh;
    top: 17vh;
    right: 1vh;
  }

  .background__leaves--right-bottom {
    background-image: url(../../images//desktop/desk_listki_duze2.png);
    width: 21vh;
    height: 40vh;
    bottom: 0;
    right: 2vh;
  }

  .background__leaves--left-bottom {
    background-image: url(../../images//desktop/desk_listki_duze2.png);
    transform: scaleX(-1);
    width: 21vh;
    height: 40vh;
    bottom: -2vh;
    left: -2vh;
  }

  .background__tree {
    position: absolute;
    height: 65vh;
    width: 30vh;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 0;
  }

  .background__tree--left {
    background-image: url(../../images/desktop/sosna_A.png);
    left: 10vh;
  }

  .background__tree--right {
    background-image: url(../../images/desktop/sosna_B.png);
    right: 10vh;
  }
}


@keyframes bubbles {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.01);
    opacity: 0.7;
  }
}
