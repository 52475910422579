.modal-additional {
    background-image: url(../../images/desktop/modal_tlo_ramka.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border: 3px solid white;
}

.modal-additional .h4,
.modal-additional .modal-footer button {
    font-weight: bold;
    color: white;
}
