.gift-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.gift-container__image {
  margin: 2vh;
  width: 100%;
  height: 100%;
}
.gift-container__text {
  width: 100%;
  height: 100%;
}

.gift-container__info {
  font-weight: bold;
  font-size: 20px;
  color: black;
  text-decoration: underline;
}

.gift-container__display {
  height: 75vw;
  width: 75vw;
  margin: 5vh;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.gift-container__download {
  cursor: pointer;
  min-height: 30vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

.gift-container__display-inner-box {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.gift-container__download a {
  width: 100%;
}

.gift-container > iframe {
  min-width: 80vw;
}

.gift-anchor-content {
  color: transparent;
  visibility: hidden;
}

.white-link {
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding-top: 1rem;
}
.white-link:hover {
  color: white;
  text-decoration: underline;
}

/* particular days: */

.operacja_szopka {
  background-image: url(../../dataBase/files/operacja_szopka_1_12.jpg);
}
.konkurs {
  background-image: url(../../dataBase/files/kalendarze_adwentowe_2_12.jpg);
}
.konkurs_wojna {
  background-image: url(../../dataBase/files/konkurs_wojna_cukierkowa_4_12.jpg);
}
.emi_fragment {
  background-image: url(../../dataBase/files/emi_fragment_5_12.jpg);
}
.chatka-mikolaja {
  background-image: url(../../dataBase/files/kolorowanka_mikolaja_6_12.jpg);
}
.balwanek {
  background-image: url(../../dataBase/files/siodmy_grudnia_kolorowanka_balwanka.jpg);
}
.pierniczki {
  background-image: url(../../dataBase/files/osmy_grudnia_pierniczki.jpg);
}
.diy {
  background-image: url(../../dataBase/files/DIY_9_12.jpg);
}
.nutcracker {
  background-image: url(../../dataBase/files/10_grudnia_dziadek_do_orzechow.jpg);
}
.roznice {
  background-image: url(../../dataBase/files/11_grudnia_znajdz_roznice.jpg);
}
.marvellers {
  background-image: url(../../dataBase/files/12_grudnia_Marvellers.jpg);
}
.konkurs_wygraj_zestaw {
  background-image: url(../../dataBase/files/13_grudnia_konkurs.jpg);
}
.odszukaj_policz {
  background-image: url(../../dataBase/files/14_grudnia_odszukaj_ii_policz.jpg);
}
.mopsik {
  background-image: url(../../dataBase/files/15_grudnia_fragment_mopsik.jpg);
}
.renifer {
  background-image: url(../../dataBase/files/16_grudnia_renifer_szuka_drogi.jpg);
}
.hooky {
  background-image: url(../../dataBase/files/hooky_zapowiedz.jpg);
}
.narysuj_niedzwiedzia {
  background-image: url(../../dataBase/files/narysuj_niedzwiedzia.jpg);
}
.ostatni_niedzwiedz {
  background-image: url(../../dataBase/files/ostatni_niedzwiedz_1912.jpg);
}
.karta_swiateczna {
  background-image: url(../../dataBase/files/kartka_swiateczna_2012.jpg);
}
.lightfall {
  background-image: url(../../dataBase/files/lightfall_fragment.jpg);
}
.list_do_m {
  background-image: url(../../dataBase//files/tysiac_naklejek.jpg);
}


.image__sale {
  border: 3px solid white;
  box-shadow: 0 0 15px 9px #adff2f;
}

@media (min-width: 800px) and (orientation: landscape) {
  .gift-container__image {
    margin: 2vh;
    width: 60%;
    height: 60%;
  }
  .gift-container__text {
    width: 75%;
  }
  .gift-container__display {
    height: 55vh;
    width: auto;
    margin: 2vh;
  }
  .gift-container__download {
    min-height: 30vw;
  }
  .gift-container > iframe {
    min-width: 45vw;
    min-height: 25vw;
  }
  .gift-mikolajki {
    background-image: url(../../images/desktop/fun_kalendarz_swiateczny_mikolajki.jpg);
  }
  .gift-renifer {
    background-image: url(../../images/desktop/fun_kalendarz_swiateczny_RENIFERi.jpg);
  }
  .gift-piernik {
    background-image: url(../../images/desktop/fun_kalendarz_swiateczny_piernik.jpg);
  }
}
