.main-container .window {
  height: 33vw;
  width: 18vw;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 3vw;
  padding: 0;
  z-index: 5;
}

@media (min-width: 750px) and (orientation: portrait) {
  .main-container .window {
    height: 20vw;
    width: 14vw;
    background-size: contain;
  }
}

@media (min-width: 800px) and (orientation: landscape) {
  .main-container .window {
    height: 21vh;
    width: 12vh;
  }
}
.window--picture-1 {
  background-image: url(../../images/desktop/desk_okno_1.png);
}
.window--picture-2 {
  background-image: url(../../images/desktop/desk_okno_2.png);
}
.window--picture-3 {
  background-image: url(../../images/desktop/desk_okno_3.png);
}
.window--picture-4 {
  background-image: url(../../images/desktop/desk_okno_4.png);
}
.window--picture-5 {
  background-image: url(../../images/desktop/desk_okno_5.png);
}
.window--picture-6 {
  background-image: url(../../images/desktop/desk_okno_6.png);
}
.window--picture-7 {
  background-image: url(../../images/desktop/desk_okno_7.png);
}
.window--picture-8 {
  background-image: url(../../images/desktop/desk_okno_8.png);
}
.window--picture-9 {
  background-image: url(../../images/desktop/desk_okno_9.png);
}
.window--picture-10 {
  background-image: url(../../images/desktop/desk_okno_10.png);
}
.window--picture-11 {
  background-image: url(../../images/desktop/desk_okno_11.png);
}
.window--picture-12 {
  background-image: url(../../images/desktop/desk_okno_12.png);
}
.window--picture-13 {
  background-image: url(../../images/desktop/desk_okno_13.png);
}
.window--picture-14 {
  background-image: url(../../images/desktop/desk_okno_14.png);
}
.window--picture-15 {
  background-image: url(../../images/desktop/desk_okno_15.png);
}
.window--picture-16 {
  background-image: url(../../images/desktop/desk_okno_16.png);
}
.window--picture-17 {
  background-image: url(../../images/desktop/desk_okno_17.png);
}
.window--picture-18 {
  background-image: url(../../images/desktop/desk_okno_18.png);
}
.window--picture-19 {
  background-image: url(../../images/desktop/desk_okno_19.png);
}
.window--picture-20 {
  background-image: url(../../images/desktop/desk_okno_20.png);
}
.window--picture-21 {
  background-image: url(../../images/desktop/desk_okno_21.png);
}
.window--picture-22 {
  background-image: url(../../images/desktop/desk_okno_22.png);
}
.window--picture-23 {
  background-image: url(../../images/desktop/desk_okno_23.png);
}
.window--picture-24 {
  background-image: url(../../images/desktop/desk_okno_24.png);
}
